import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css"; // 此处看下载的swiper版本是多少 引入对应的css文件 具体看下方5.注意
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import BannerView  from './components/BannerView.vue';
import PixelBannerView  from './components/PixelBannerView.vue';
import ChartBannerView  from './components/ChartBannerView.vue';
//插件需要注入，==如果你不引用swiper的东西你会发现swiper不会自动播放==
SwiperCore.use([Autoplay]).use(Pagination)

createApp(App).use(store).use(router).use(ElementPlus).use(VueAwesomeSwiper).component('BannerView', BannerView).component('PixelBannerView', PixelBannerView).component('ChartBannerView', ChartBannerView).mount('#app')
