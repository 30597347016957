<template>
    <div>
        <swiper
          :modules="modules"
	        :loop="true"
	        :slides-per-view="1"
	        :space-between="10"
	        :autoplay="true"
	        :navigation="navigation"
	        :pagination="{clickable: true }"
   	      class="swiperBox"
   	      @slideChange="onSlideChange">
          <SwiperSlide>
            <img :src="screenshot1" class="screenshot"/>
          </SwiperSlide>
          <SwiperSlide>
            <img :src="screenshot2" class="screenshot"/>
          </SwiperSlide>
          <SwiperSlide>
            <img :src="screenshot3" class="screenshot"/>
          </SwiperSlide>
          <SwiperSlide>
            <img :src="screenshot4" class="screenshot"/>
          </SwiperSlide>
        </swiper>
    </div>
</template>

<script>

export default{
    name: "PixelBannerView",
    components: {
    },
    setup(){
        return {
          screenshot1:require('@/assets/pixel1.png'),
          screenshot2:require('@/assets/pixel2.png'),
          screenshot3:require('@/assets/pixel3.png'),
          screenshot4:require('@/assets/pixel4.png'),
        }
    },
    data() {
        return {}
    },

};
</script>
<style>
  .swiperBox{
    height: 740px;
    width: 340px;
  }
  .screenshot {
    height: 700px;
    width: 340px;
    padding-bottom: 40px;
  }
</style>